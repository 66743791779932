<template>
  <a-spin :spinning="spinning">
    <ele-table
      ref="rt"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
        <a-col :span="5">
          <a-input v-model="code" placeholder="请输入共用码" allowClear />
        </a-col>
        <!-- <a-col :span="5">
          <a-input v-model="filterText" placeholder="请输入关键字" allowClear />
        </a-col> -->
        <a-col :span="11">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.WanyooStore.Query')"
          >
            <a-icon type="search" />
            {{ l("搜索") }}
          </a-button>
          <a-button @click="clearFilterAndRefresh">
            {{ l("重置") }}
          </a-button>
        </a-col>

        <a-col align="right" :span="8">
          <a-button-group>
            <a-button
              @click="createReactModify(null, 'others')"
              type="primary"
              v-if="isGranted('Pages.WanyooStore.Create')"
            >
              <a-icon type="plus" />
              {{ l("Create") }}
            </a-button>
            <a-button
              v-if="isGranted('Pages.WanyooStore.ImportExcel')"
              @click="openImportform()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("导入") }}</span>
            </a-button>
            <a-button
              v-if="isGranted('Pages.WanyooStore.ExportExcel')"
              @click="exportToExcel()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("导出") }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy, initColumnsWidth } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import ImportForm from "./importform/importform.vue";
import { fileDownloadService } from "@/shared/utils";

export default {
  components: {
    EleTable,
    ReactModify,
  },
  mixins: [AppComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      columns: [],
      actionsType: {},
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      code: "",
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.spinning = true;
      const tWidth = this.$refs.rt.$el.clientWidth;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/WanyooStore/GetPaged",
          params: {
            ...this.request,
            filterText: this.filterText,
            code: this.code,
          },
        });

        const oJson = res.items.map((item) => {
          return {
            ...item,
            // configContent: this.isJSON(item.configContent)
            //   ? JSON.parse(item.configContent)
            //   : {},
          };
        });
        // console.log("oJson:", oJson);
        let tableData = [];
        let cols = [];
        const columnsLength = oJson.length
          ? Object.keys(oJson[0].configContentFormat).length
          : 0;
        const actionWidth = 100;
        oJson.forEach((item, index) => {
          let obj = {};
          for (let k in item.configContentFormat) {
            if (index === 0) {
              cols.push({
                title: item.configContentFormat[k].label,
                dataIndex: k,
                width: initColumnsWidth(
                  tWidth,
                  columnsLength,
                  actionWidth,
                  true
                ),
              });
            }
            obj[k] = item.configContentFormat[k].value;
            obj.id = item.id;
            obj.code = item.code;
          }
          // item.configContentFormat.forEach((it, i) => {
          //   if (index === 0) {
          //     cols.push({

          //       title: it.Value.Label,
          //       dataIndex: it.Key,
          //       // align: "center",
          //       width: "120",
          //     });
          //   }
          //   obj[it.Key] = it.Value.Value;
          //   obj.id = item.id
          //   obj.code = item.code
          // });
          tableData.push(obj);
        });
        cols.push({
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          width: actionWidth,
        });
        this.tableData = tableData;
        this.totalItems = res.totalCount;
        this.columns = cols;
        this.initActionsType();
        // console.log("tableData", tableData);
        // console.log("cols", cols);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          let obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.log("error：" + str + "!!!" + e);
          return false;
        }
      }
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    clearFilterAndRefresh() {
      this.code = "";
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.filterText = "";
      this.getData();
    },
    showTotalFun() {
      const res = this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
      return res;
    },
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.WanyooStore.Edit"),
              name: this.l("修改"),
              icon: "edit",
              fn: (data) => {
                console.log(data);
                _this.createReactModify(data, "edit");
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    async createReactModify(item, type) {
      let list = [];
      const orginListLength = list.length;
      let reqParams = [];
      if (item && item.id) {
        // console.log(item)
        reqParams.push({ key: "id", value: item.id });
      } else {
        reqParams.push({ key: "id", value: null });
      }
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById: "/api/services/app/WanyooStore/GetExtendInfoById",
              save: "/api/services/app/WanyooStore/SaveWanyooStore",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              res.formItems.map((_item, index) => {
                switch (_item.itemType) {
                  case "Text":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonInput",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Textarea":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "textarea",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Number":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "numberInput",
                      disabled: false,
                      required: _item.isRequire,
                      max: _item.maxLength,
                      min: 0,
                      key: _item.key,
                      rules: [],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  case "Select":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonSelect",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: "",
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Checkbox":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "checkbox",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Radio":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "radio",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Date":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "datePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  case "Upload":
                    list.push({
                      label: _item.name,
                      value: [],
                      type: "upload",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [],
                      subdirName: environment.production ? "abpProd" : "abpDev",
                      uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
                      limitedLength: _item.maxLength,
                      span: 24,
                      changeFn: (info) => {
                        let fileList = [...info.fileList];
                        fileList = fileList.map((file) => {
                          if (file.response) {
                            file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
                          }
                          return file;
                        });
                        list[_item.sort + orginListLength].value = fileList;
                      },
                    });
                    break;
                  case "DateRange":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "rangeDatePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "array",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  default:
                    throw new Error("暂无匹配项目");
                }
              });

              if (!item) {
              } else {
                form.setFieldsValue({
                  ProjectName: item.projectName,
                  ProjectType: item.projectType,
                  OrganizationUnitId: item.organizationUnitId,
                  ProjectTime: [item.beginDate, item.endDate],
                  CustomerName: item.customerName,
                  ProjectCode: item.projectCode,
                });
              }
              // debugger;
              if (res.results && Object.keys(res.results).length !== 0) {
                const oResult = res.results;
                let opts = {};
                list.map((it) => {
                  if (it.key) {
                    let k = it.key;
                    if (it.type === "upload") {
                      if (oResult[k].value.indexOf(",") !== -1) {
                        let arr = oResult[k].value.split(",");
                        arr.map((val, i) => {
                          let n = val.split("/");
                          it.value.push({
                            uid: `-${i + 1}`,
                            url: `${AppConsts.uploadBaseUrl}${val}`,
                            name: n[n.length - 1],
                            response: { data: { Path: val } },
                            status: "done",
                          });
                        });
                      } else {
                        if (oResult[k].value && oResult[k].value.length) {
                          let n = oResult[k].value.split("/");
                          it.value.push({
                            uid: `-1`,
                            url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
                            name: n[n.length - 1],
                            response: { data: { Path: res.pictureUrls } },
                            status: "done",
                          });
                        }
                      }
                    } else if (it.type === "rangeDatePicker") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf("至") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split("至");
                    } else if (it.type === "datePicker") {
                      opts[`${k}`] = oResult[k].value
                        ? moment(oResult[k].value, "YYYY-MM-DD")
                        : null;
                    } else if (it.type === "checkbox") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf(",") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split(",");
                    } else {
                      opts[`${k}`] = oResult[k]?.value ?? "";
                    }
                  }
                });
                // console.log(opts)
                this.$nextTick(() => {
                  form.setFieldsValue({ ...opts });
                });
              }

              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let fileUrl = [];
              for (let i = 0; i < list.length; i++) {
                if (list[i].type === "upload" && list[i].required) {
                  if (!list[i].value.length) {
                    this.$message.info(`请上传${list[i].label}的附件资源`);
                    break;
                  }
                }
              }

              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              let dynamicList = list.filter((item) => item.key);
              let _results = [];
              // console.log(dynamicList)

              dynamicList.map((_item) => {
                if (
                  _item.type === "commonInput" ||
                  _item.type === "numberInput" ||
                  _item.type === "textarea" ||
                  _item.type === "radio" ||
                  _item.type === "commonSelect"
                ) {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value: values[`${_item.value}`]
                        ? values[`${_item.value}`]
                        : null,
                    },
                  });
                } else if (_item.type === "datePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      // value: values[`${_item.value}`].format('YYYY-MM-DD')
                      value: values[`${_item.value}`],
                    },
                  });
                } else if (_item.type === "rangeDatePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`][0] +
                        "至" +
                        values[`${_item.value}`][1],
                    },
                  });
                } else if (_item.type === "checkbox") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`].length === 1
                          ? values[`${_item.value}`][0]
                          : values[`${_item.value}`].join(","),
                    },
                  });
                } else if (_item.type === "upload") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        _item.value.length === 1
                          ? `${_item.value[0].response.data.Path}`
                          : _item.value
                              .map((v) => {
                                return v.response.data.Path;
                              })
                              .join(","),
                    },
                  });
                }
              });

              let submitData = {
                Id: item && item.id ? item.id : null,
                ConfigContent: JSON.stringify(_results),
              };
              // console.log('submitData:', submitData)

              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...submitData },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    openImportform() {
      ModalHelper.create(
        ImportForm,
        { formId: "22222222-2222-2222-2222-222222222222" },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        this.clearFilterAndRefresh();
      });
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/WanyooStore/GetToExcelFile",
          params: {
            ...this.request,
            filterText: this.filterText,
            code: this.code,
          },
        });
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>

